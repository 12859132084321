<template>
  <DgChart :data="widget" :language="language" :themeChart="themeChart" />
</template>
<script>
import { mapState } from "vuex";
import { DEFAULT_LANGUAGE } from "@/constants/language";
import MixinFixWidget from "@/mixins/fixWidget";
import { DgChart } from "@engenharia/dashgoo-widgets";

export default {
  name: "RenderWidgetChart",
  mixins: [MixinFixWidget],
  props: {
    reportSettings: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapState("dashboard", ["dashboardLanguage"]),
    language() {
      return this.dashboardLanguage || DEFAULT_LANGUAGE;
    },
    themeChart() {
      return this.reportSettings?.themeCharts;
    },
  },
  components: {
    DgChart,
  },
};
</script>
